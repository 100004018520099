import { Grid } from "@mui/material";
import { Control } from "react-hook-form";
import { z } from "zod";

import { SafeString } from "@packages/types";

import { IAgentInfo } from "~/api";
import { FormSection } from "~/components/form/FormSection";
import { FormAutocomplete } from "~/components/form/FormAutocomplete";

import { ApplicationFields } from "./useApplicationForm";

export interface DelegatedAgencyFormProps {
  control: Control<ApplicationFields>;
  disabled?: boolean;
  agencyPartners?: IAgentInfo["agencyPartners"];
}

export function DelegatedAgencyForm(props: DelegatedAgencyFormProps) {
  const { control, disabled, agencyPartners = [] } = props;

  // Use "" to represent the "None" option
  const orgUnitCodes = [""].concat(agencyPartners.map(({ orgUnitCode }) => orgUnitCode));

  return (
    <>
      <FormSection.Well>
        If you require another agency branch to manage this application after submission,
        please select that branch from the dropdown below. If you're not delegating this
        application, please select "None".
      </FormSection.Well>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormAutocomplete
            control={control}
            name="delegatedAgency.delegatorOrgUnitCode"
            label="Delegated Agency Branch"
            disabled={disabled}
            // Autocomplete doesn't have a mechanism to disable an "empty" state (which
            // has a value of `null`) so we get around this by setting a default value
            // and also converting any `null` values to "" in useApplicationForm.utils
            defaultValue=""
            options={orgUnitCodes}
            getOptionLabel={(orgUnitCode) =>
              agencyPartners.find((partner) => partner.orgUnitCode === orgUnitCode)
                ?.name ?? "None"
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

DelegatedAgencyForm.draftSchema = z.object({
  delegatorOrgUnitCode: SafeString.schema().nullable(),
});

DelegatedAgencyForm.submitSchema = DelegatedAgencyForm.draftSchema;
