import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useRef, useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import * as Sentry from "@sentry/react";
import { z } from "zod";

import { createAddIssue, parseRequestError } from "~/utils";
import { ApplicationApi, GetApi, IAgentInfo, IApplicant, IApplication } from "~/api";
import { useApplicationForm } from "~/components/application/ApplicationForm";
import { useNotification } from "~/components/core/NotificationProvider";
import { createApplicationUpdatePayload } from "~/components/application/ApplicationForm/useApplicationForm.utils";

import { CUSTOM_AGENT_TRANSFER_ERROR_MESSAGE } from "./AgentDeclarationForm";

export type AgentDeclarationFields = z.infer<typeof AgentDeclarationSchema>;

const AgentDeclarationSchema = z
  .object({
    checklist: z.object({
      consentGiven: z.boolean(),
      contactDetailsVerified: z.boolean(),
      officialDocumentationSighted: z.boolean(),
      officialDocumentationPending: z.boolean(),
      onlineResultsVerified: z.boolean(),
      informationTrueAndCorrect: z.boolean(),
    }),
    officeBranch: z.string().trim(),
    name: z.string().trim(),
  })
  .superRefine(
    (
      {
        checklist: {
          consentGiven,
          contactDetailsVerified,
          officialDocumentationSighted,
          officialDocumentationPending,
          onlineResultsVerified,
          informationTrueAndCorrect,
        },
      },
      ctx,
    ) => {
      const addIssue = createAddIssue(ctx);
      if (
        !consentGiven ||
        !contactDetailsVerified ||
        !onlineResultsVerified ||
        !informationTrueAndCorrect
      )
        addIssue(
          "Please select the appropriate declarations before submitting the application",
          "checklist",
        );
      // only one of these two can be true at once
      if (
        (officialDocumentationSighted && officialDocumentationPending) ||
        (!officialDocumentationSighted && !officialDocumentationPending)
      ) {
        addIssue(
          "Please select only one of the two declarations highlighted below",
          "checklist",
        );
        addIssue(
          "Please select ONE of these two fields",
          "checklist.officialDocumentationSighted",
        );
        addIssue(
          "Please select ONE of these two fields",
          "checklist.officialDocumentationPending",
        );
      }
    },
  );

export type UseAgentDeclarationForm = ReturnType<typeof useAgentDeclarationForm>;

export function useAgentDeclarationForm(options: {
  applicant: IApplicant;
  application: IApplication;
  englishCourseCodes: string[];
  monashCollegeFaculties: string[];
  user?: IAgentInfo | null;
}) {
  const { applicant, application, englishCourseCodes, monashCollegeFaculties, user } =
    options;
  const { agent, agency } = user ?? {};

  const formRef = useRef<HTMLFormElement>(null);
  const { showNotification, showNotificationAlert, showErrorAlert, closeNotification } =
    useNotification();

  const { applicantId } = applicant;
  const { applicationId } = application.application;

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const form = useForm<AgentDeclarationFields>({
    resolver: zodResolver(AgentDeclarationSchema),
    defaultValues: {
      checklist: {
        consentGiven: false,
        contactDetailsVerified: false,
        officialDocumentationSighted: false,
        officialDocumentationPending: false,
        onlineResultsVerified: false,
        informationTrueAndCorrect: false,
      },
      officeBranch: agency?.name ?? "",
      name: `${agent?.givenNames ?? ""} ${agent?.familyName ?? ""}`.trim(),
    },
  });

  const { form: applicationForm } = useApplicationForm({
    applicant,
    application,
    englishCourseCodes,
    monashCollegeFaculties,
  });

  const onValidSubmit = useCallback(
    async (declaration: AgentDeclarationFields) => {
      setError("");
      setLoading(true);
      showNotification({
        type: "loading",
        message: "Submitting application...",
      });

      const { application } = createApplicationUpdatePayload(
        { applicantId, applicationId },
        applicationForm.getValues(),
        declaration,
      );
      application.application.applicationStatus = "Agent Declaration Accepted";

      try {
        const api = GetApi(ApplicationApi);
        await api.updateApplication(application.application.applicationId, application);
        setSubmitted(true);
        closeNotification();
      } catch (error) {
        // SFTG-3660: Check for agent transfer error and display custom error message
        const requestError = parseRequestError(error);
        if (requestError?.details === MIX_AGENT_TRANSFER_ERROR_MESSAGE) {
          showNotificationAlert({
            type: "error",
            title: "Unable to submit application",
            maxWidth: "sm",
            message: CUSTOM_AGENT_TRANSFER_ERROR_MESSAGE,
          });
        } else {
          // send error to Sentry
          Sentry.captureException(error, {
            tags: { source: "useAgentDeclarationForm.onValidSubmit" },
          });

          showErrorAlert(error, "Unable to submit application");
        }
      } finally {
        setLoading(false);
      }
    },
    [applicationForm],
  );

  const onInvalidSubmit = useCallback((errors: FieldErrors<AgentDeclarationFields>) => {
    // has the API of react-hook-form changed? Don't recall `root` being a property before
    setError(errors.checklist?.root?.message ?? "Invalid selection");
  }, []);

  return {
    form,
    formRef,
    loading,
    submitted,
    error,
    onValidSubmit,
    onInvalidSubmit,
  };
}

const MIX_AGENT_TRANSFER_ERROR_MESSAGE =
  "Applicant is already affiliated to another Agent. Applicant needs to complete Agent nomination process. Agent will be informed once request is processed.";
